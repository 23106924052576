import Sortable from 'sortablejs'

class thumbnailPriority {
  constructor() {
    var el = document.getElementById('thumbnail_priority');
    if (el != null) {
      var sortable = Sortable.create(el, {
        onSort: function(evt) {
          var items = el.querySelectorAll('div.list-group-item');
          for (var i = 0; i < items.length; i++) {
            document.getElementById(items[i].getAttribute("data-type")).value = i + 1;
          }
        }
      });
    }
  }
}

window.addEventListener('turbolinks:load', () => new thumbnailPriority())